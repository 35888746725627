@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Regular"; /*Can be any text*/
  src: local("Tahoma_Regular_font"),
    url("./fonts/Tahoma_Regular_font.ttf") format("truetype");
}

@font-face {
  font-family: "Bold"; /*Can be any text*/
  src: local("TAHOMABD"), url("./fonts/TAHOMABD.ttf") format("truetype");
}

input::placeholder {
  font-weight: bold;
  opacity: 1;
  color: black;
}

body {
  background-color: #1555aa;
}

.regular {
  font-family: "Regular";
}

.bold {
  font-family: "Bold";
}

.main {
  background-color: #1555aa;
}

.sign-in-color {
  background-color: #508fd9;
}
.sign-in-top {
  border-image-source: linear-gradient(
    90deg,
    #004da3 0%,
    rgba(255, 255, 255, 0.8) 30%,
    rgba(255, 255, 255, 0.9) 40%,
    rgba(255, 255, 255, 0.9) 50%,
    #004da3 100%
  );
  border-width: 0 0 4px 0;
  background-color: #004da3;
  flex-basis: 12.5%;
  border-image-slice: 1;
}

.sign-in-bottom {
  border-image-source: linear-gradient(
    90deg,
    #004da3 0%,
    #f09644 30%,
    #f09644 40%,
    #f09644 50%,
    #004da3 100%
  );
  background-color: #004da3;
  border-width: 4px 0 0 0;
  flex-basis: 12.5%;
  border-image-slice: 1;
}

.sign-in-main {
  flex: 1;
  display: flex;
  background-image: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.6) -21%,
    rgba(255, 255, 255, 0) 60%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 700px 700px;
  background-position: -240px -210px;
  background-repeat: no-repeat;
}

.sign-in-splash {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 30px;
  align-items: flex-end;
  border-right-width: 1px;
  border-image-source: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 5%,
    rgba(255, 255, 255, 0.5) 15%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.5) 85%,
    rgba(255, 255, 255, 0) 95%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 0 1 0 0;
  border-right-style: solid;
}

.sign-in-account {
  background: linear-gradient(90deg, #00489a 60%, rgba(255, 255, 255, 0) 100%);
  border-radius: 8px;
}

.password {
  box-shadow: #00489a 2px 2px 0px 0px;
}

.footer {
  background: linear-gradient(
    180deg,
    #3168d5,
    #4a92e6 7%,
    #255dd6 12%,
    #2258d6,
    15%,
    #2563e0 95%,
    #1e50c3
  );
}

.start-button {
  content: "";

  background: linear-gradient(
    180deg,
    #29662e,
    #7dca82 7%,

    #3b7e39 12%,
    #307e31,
    #359637,
    #339731
  );
}

.time-tray {
  background: linear-gradient(
    180deg,
    #0b6cd1,
    #149ce8 2%,
    #1ab9f4 7%,
    #14a2ef 13%,
    #1292e9 15%,
    #1386e1 50%,
    #108ae4 90%,
    #147fdd 95%
  );
}

.backgroundDiv {
  background-image: url("./images/xp_bg.jpeg");
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
  width: 100%;
  height: 100%; /* Example height, adjust as needed */
}

.start-menu {
  background: linear-gradient(
    180deg,
    #204b96,
    #70a7e7,
    #1555aa 8px,
    #4087df 80px,
    #4488db calc(100% - 80px),
    #0d4ba3 calc(100% - 3px),
    #0d4aa5
  );
  box-shadow: inset 0 0 20px 10px #0e53b9b3;
}

.window {
  position: absolute;
  background: linear-gradient(
    180deg,
    #3168d5,
    #4a92e6 2px,
    #255dd6 5px,
    #2258d6,
    15px,
    #2563e0 95%,
    #1e50c3
  );
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
  will-change: filter;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 10px #00000080;
  /* transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out; */
}

.window-bg {
  background: linear-gradient(
    180deg,
    #3168d5,
    #4a92e6 2px,
    #255dd6 5px,
    #2258d6,
    15px,
    #2563e0 95%,
    #1e50c3
  );
}

.window-bg-java {
  background: linear-gradient(
    180deg,
    #d59331,
    #e6a24a 2px,
    #d68625 5px,
    #d67f22,
    15px,
    #e08625 95%,
    #c36b1e
  );
}

.window-bg-python {
  background: linear-gradient(
    180deg,
    #d5d531,
    #e6e34a 2px,
    #d6d025 5px,
    #d6d022,
    15px,
    #e0d725 95%,
    #c3b81e
  );
}

.window-bg-react {
  background: linear-gradient(
    180deg,
    #52c3df,
    #61dbfb 2px,
    #52c3df 5px,
    #52c3df,
    15px,
    #2fa9c7 95%,
    #2995b0
  );
}

.window-bg-next {
  background: linear-gradient(
    180deg,
    #141413,
    #727272 2px,
    #1a1a1a 5px,
    #1b1b1b,
    15px,
    #181818 95%,
    #202020
  );
}

.window-bg-express {
  background: linear-gradient(
    180deg,
    #fcfcfc,
    rgb(229, 229, 229) 2px,
    #dad9d9 5px,
    #e6e6e6,
    15px,
    #e6e6e6 95%,
    #d2d2d2
  );
}

.divider {
  background: linear-gradient(90deg, #fff, #fdc166, #f7b555, #f7b555, #d3e5fb);
}

.divider2 {
  background: linear-gradient(90deg, #fff, #c4c4c4, #a1a1a1, #c4c4c4, #fff);
}

.divider3 {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 5%,
    rgba(255, 255, 255, 0.5) 15%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.5) 85%,
    rgba(255, 255, 255, 0) 95%,
    rgba(255, 255, 255, 0) 100%
  );
}

.drop-shad {
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
}

.start-context-right {
  background-color: #d3e5fb;
  border-left: 1px solid rgb(154, 192, 239);
}
